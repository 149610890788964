import React, { useReducer } from "react";

//context
import UsuarioContext from "./UsuarioContext";

//reducer
import UsuarioReducer from "./UsuarioReducer";
//cliente axios
import clienteAxios from "../../config/axios";
import { saveAs } from "file-saver";
import {
  ELIMINAR_USUARIO,
  ELIMINAR_USUARIO_ERROR,
  CAMBIAR_USUARIO,
  EDITAR_USUARIO,
  EDITAR_USUARIO_ERROR,
  AGREGAR_USUARIO,
  AGREGAR_USUARIO_ERROR,
  OBTENER_USUARIOS,
  OBTENER_USUARIOS_ERROR,
  LIMPIAR_MENSAJE,
  CARGANDO,
} from "../types/index";
const UsuarioState = (props) => {
  const initialState = {
    columns: [
      { id: "nombres", label: "Nombres", minWidth: 100, align: "left" },
      {
        id: "userName",
        label: "Usuario",
        minWidth: 100,
      },
      {
        id: "email",
        label: "Email",
        minWidth: 100,
      },
      {
        id: "rol",
        label: "Rol",
        minWidth: 100,
      },
      {
        id: "estado",
        label: "Estado",
        minWidth: 105,
      },
      {
        id: "empresa",
        label: "Empresa",
        minWidth: 105,
      },

      { id: "codEmpresa", label: "codEmpresa", minWidth: 100 },

      {
        id: "boton",
        label: "Editar/Eliminar",
        minWidth: 105,
        align: "right",
        key: "llave7",
      },
    ],
    usuarios: null,
    usuarioActivo: {
      codEmpresa: "",
      nombres: "",
      userName: "",
      email: "",
      password: "",
      rol: "usuario",
      boton: "Create",
      estado: "ACTIVO",
    },
    mensaje: null,
    cargandoUsuarios: true,
  };

  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  const onChangeUsuarioActivo = async (usuario) => {
    
    dispatch({
      type: CAMBIAR_USUARIO,
      payload: usuario,
    });
  };

  const obtenerUsuarios = async () => {
    try {
      const usuarios = await clienteAxios.get("/usuario");

      dispatch({
        type: OBTENER_USUARIOS,
        payload: usuarios.data.usuarios,
      });
      quitarMensaje();
    } catch (error) {
      dispatch({
        type: OBTENER_USUARIOS_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const editarUsuario = async (datos) => {
    cargando();
    try {
      await clienteAxios.post("/usuario/editar", datos);
      dispatch({
        type: EDITAR_USUARIO,
        payload: datos,
      });
      quitarMensaje();
    } catch (error) {
      dispatch({
        type: EDITAR_USUARIO_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const agregarUsuario = async (datos) => {
    cargando();
    try {
      const id = await clienteAxios.post("/usuario", datos);

      datos._id = id.data.id;
      dispatch({
        type: AGREGAR_USUARIO,
        payload: datos,
      });
      quitarMensaje();
    } catch (error) {
      dispatch({
        type: AGREGAR_USUARIO_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const removerUsuario = async (_id) => {
    cargando();
    try {
      const msg = await clienteAxios.post("/usuario/eliminar", { _id });

      const payload = {
        _id,
        msg: msg.data.msg,
      };
      quitarMensaje();
      dispatch({
        type: ELIMINAR_USUARIO,
        payload,
      });
    } catch (error) {
      dispatch({
        type: ELIMINAR_USUARIO_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const generarPdf = async (cuenta) => {
    let datos;
    if (cuenta.rol === "admin") {
      datos = {
        columns: state.columns.filter((columna) => columna.id !== "boton"),
        usuarios: state.usuarios,
      };
    } else {
      datos = {
        columns: state.columns.filter((columna) => columna.id !== "boton"),
        usuarios: state.usuarios.filter(
          (fila) => fila.empresa === cuenta.empresa
        ),
      };
    }
    
    const pdf = await clienteAxios.post(
      "/pdf/usuario",
      { datos },
      { responseType: "blob" }
    );
    const pdfBlob = new Blob([pdf.data], { type: "application/pdf" });
    saveAs(pdfBlob, "usuarios.pdf");
  };

  const quitarMensaje = () => {
    setTimeout(() => {
      dispatch({
        type: LIMPIAR_MENSAJE,
      });
    }, 4000);
  };
  const cargando = () => {
    dispatch({
      type: CARGANDO,
    });
  };
  return (
    <UsuarioContext.Provider
      value={{
        columns: state.columns,
        usuarios: state.usuarios,
        usuarioActivo: state.usuarioActivo,
        mensaje: state.mensaje,
        cargandoUsuarios: state.cargandoUsuarios,
        //funciones
        onChangeUsuarioActivo,
        obtenerUsuarios,
        agregarUsuario,
        editarUsuario,
        removerUsuario,
        generarPdf,
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;
