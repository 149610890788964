import React from "react";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "./layouts/Admin";
import Login from "./views/Login/Login";
import Recuperar from "./views/Recuperar/Recuperar";
import RutaPrivada from "./components/rutas/RutaPrivada";
import "./assets/css/material-dashboard-react.css?v=1.9.0";

//token config
import tokenAuth from "./config/token";
//context
import EmpresaState from "./Context/Empresa/EmpresaState";
import AuthState from "./Context/auth/AuthState";
import AlertaState from "./Context/alertas/AlertaState";
import MensajeState from "./Context/Mensajes/MensajeState";
import UsuarioState from "./Context/Usuario/UsuarioState";

//revisando si hay un token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  const hist = createBrowserHistory();
  return (
    <UsuarioState>
      <MensajeState>
        <AlertaState>
          <AuthState>
            <EmpresaState>
              <Router history={hist}>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/Recuperar" component={Recuperar} />
                  <RutaPrivada path="/admin" component={Admin} />
                  <Redirect from="/" to="/login" />
                </Switch>
              </Router>
            </EmpresaState>
          </AuthState>
        </AlertaState>
      </MensajeState>
    </UsuarioState>
  );
}

export default App;
