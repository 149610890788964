import React, { useState, useContext, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Create, DeleteForever, AddCircle } from "@material-ui/icons";
import Modal from "../Modal/Modal";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  IconButton,
  Box,
} from "@material-ui/core";
import EmpresaContext from "../../Context/Empresa/EmpresaContext";
import AuthContext from "../../Context/auth/AuthContext";

//simulacion de la base de datos filas

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "440",
  },
  paper: {
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  align: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Tabla = () => {
  //extrayendo valores del context
  const {
    filas,
    columnas,
    removerEmpresa,
    empresaActiva,
    onChangeEmpresaActiva,
    obtenerEmpresas,
  } = useContext(EmpresaContext);
  const { cuenta } = useContext(AuthContext);
  const clases = useStyles();

  //define si el modal seabre o no
  const [open, setopen] = useState(false);
  //datos de la empresa que se selcciona

  //funcion que abre el modal
  const handleOpen = () => {
    setopen(true);
  };

  //funcion que cierrra el modal
  const handleClose = () => {
    //cuando se cierra el modal restablecer los valores de la empresa activa
    onChangeEmpresaActiva({
      nombre: "",
      codigo: "",
      Ruc: "",
      estado: "ACTIVO",
      Direccion: "",
      Celular: "",
      Email: "",
      smsEnviados: 0,
      rol: "empresa",
      paquete: "",
    });
    setopen(false);
  };

  const infoEmpresa = (id) => {
    //cuando se le da click a una empresa para editar
    //colocar los valores de esa empresa en el state
    const informacion = filas.filter((fila) => fila._id === id);
    console.log("fila", informacion);
    onChangeEmpresaActiva(informacion[0]);
  };

  //paginacion de la tabla
  const [pagina, setpagina] = useState(0);
  const [filasPorPagina, setfilasPorPagina] = useState(5);

  //pagina en la que te encuentras en la tabla
  const handleChangePage = (e, nuevaPagina) => {
    setpagina(nuevaPagina);
  };
  //cambiando el numero de  filas por pagina
  const handleChangeRowsPerPage = (e) => {
    setfilasPorPagina(+e.target.value);
    setpagina(0);
  };

  useEffect(() => {
    if (!filas) {
      obtenerEmpresas();
    }
  }, [filas, obtenerEmpresas]);
  if (!filas) return null;
  return (
    <Paper className={clases.root}>
      {cuenta.rol === "usuario" ? null : (
        <Box display="flex" flexDirection="row-reverse">
          <IconButton
            aria-label=""
            onClick={() => {
              handleOpen();
            }}
          >
            <AddCircle />
          </IconButton>
        </Box>
      )}

      {/* cuerpo  del modal */}
      <Modal
        open={open}
        handleClose={handleClose}
        EmpresaInfo={empresaActiva}
        setEmpresaInfo={onChangeEmpresaActiva}
      />

      {/* inicio de la tabla */}
      <TableContainer className={clases.container}>
        <Table stickyHeader aria-label="sticky table" id="tablaEmpresas" size="small">
          <TableHead>
            <TableRow>
              {/* recorriendo el encabezado */}
              {columnas.map((columna, index) => {
                if (columna.id === "boton" && cuenta.rol === "usuario") {
                  return null;
                }
                return (
                  <TableCell
                    key={columna.key}
                    align={columna.align}
                    style={{ minWidth: columna.minWidth }}
                  >
                    {columna.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* //recorriendo las filas */}
            {filas
              .slice(
                pagina * filasPorPagina,
                pagina * filasPorPagina + filasPorPagina
              )
              .map((fila, index) => {
                if (
                  fila.nombre !== cuenta.empresa &&
                  cuenta.rol === "usuario"
                ) {
                  return null;
                }
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columnas.map((columna) => {
                      const value = fila[columna.id];
                      if (columna.id === "boton" && cuenta.rol === "usuario") {
                        return null;
                      }

                      return (
                        <TableCell key={columna.key} align={columna.align}>
                          {/* creando un ternario para colocar los botones en la tabla */}

                          {value === "Create" ? (
                            <>
                              <Box display="flex" justifyContent="space-between">
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    //funcion que coloca la informacion en el state para editar la empresa seleccionada
                                    infoEmpresa(fila._id);
                                    //funcion que abre el modal
                                    handleOpen();
                                  }}
                                >
                                  <Create />
                                </IconButton>
                                <IconButton
                                  aria-label=""
                                  onClick={() => removerEmpresa(fila._id)}
                                >
                                  <DeleteForever />
                                </IconButton>
                              </Box>
                            </>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {/* pie de la tabla  */}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
        component="div"
        count={filas.length}
        rowsPerPage={filasPorPagina}
        page={pagina}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Tabla;
