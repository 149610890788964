import React, { useReducer } from "react";

import {
  INICIO_EXITOSO,
  OBTENER_USUARIO,
  LOGIN_ERROR,
  INICIO_ERROR,
  CERRAR_SESION,
  LIMPIAR_MENSAJE,
  RECUPERACION_EXITO
} from "../types/index";

//context
import AuthContext from "./AuthContext";

//reducer
import AuthReducer from "./AuthReducer";
//axios
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    cuenta: null,
    mensaje: null,
    cargando: true,
    exito : null
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const iniciarSesion = async (datos) => {
    try {
      const token = await clienteAxios.post("/login", datos);

      dispatch({
        type: INICIO_EXITOSO,
        payload: token.data,
      });
      usuarioAutenticado();
      limpiarError();
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: INICIO_ERROR,
        payload: error.response.data.msg,
      });
      limpiarError();
    }
  };

  const RecuperarContraseña = async (datos) => {
    try {
      const cuenta = await clienteAxios.post("/login/recuperar", datos);
      console.log(cuenta);
      const mensaje = await clienteAxios.post("/email", cuenta.data.usuario);
      console.log(mensaje.data.msg);
      dispatch({
        type: RECUPERACION_EXITO,
        payload: mensaje.data.msg,
      });
      limpiarError();
    } catch (error) {
      console.log(error);
      dispatch({
        type: INICIO_ERROR,
        payload: error.response.data.msg,
      });
      limpiarError();
    }
  };
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (state.usuario) {
      return;
    }
    if (token) {
      //funcion que colocca  el token en el header
      tokenAuth(token);
    }

    try {
      const usuario = await clienteAxios.get("/login");

  await     dispatch({
        type: OBTENER_USUARIO,
        payload: usuario.data.cuenta,
      });
      limpiarError();
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data.msg,
      });
      limpiarError();
    }
  };

  const limpiarError = () => {
    setTimeout(() => {
      dispatch({
        type: LIMPIAR_MENSAJE,
      });
    }, 4000);
  };
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  return (
    <AuthContext.Provider
      value={{
        //state
        usuario: state.usuario,
        autenticado: state.autenticado,
        cuenta: state.cuenta,
        mensaje: state.mensaje,
        cargando: state.cargando,
        exito :state.exito,
        //funciones
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
        RecuperarContraseña,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
