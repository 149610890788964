import React, { useReducer } from "react";

//context
import MensajeContext from "./MensajeContext";

//reducer
import MensajeReducer from "./MensajeReducer";
import clienteAxios from "../../config/axios";
import { saveAs } from "file-saver";

import {
  OBTENER_MENSAJES,
  CERRAR_SESION,
  AGREGAR_MENSAJES,
  ENVIAR_MENSAJE_ERROR,
  CARGANDO,
  LIMPIAR_MENSAJE,
} from "../types/index";
const MensajeState = (props) => {
  const initialState = {
    columns: [
      {
        id: "mensaje",
        label: "Mensaje",
        minWidth: 300,
      },
      { id: "estado", label: "Estado", minWidth: 100 },
      {
        id: "fecha",
        label: "Fecha",
        minWidth: 100,
      },
      {
        id: "celular",
        label: "Celular",
        minWidth: 100,
      },
      {
        id: "pais",
        label: "Pais",
        minWidth: 100,
      },
      {
        id: "empresa",
        label: "Empresa",
        minWidth: 100,
      },
      {
        id: "codEmpresa",
        label: "codEmpresa",
        minWidth: 100,
      },
      {
        id: "usuario",
        label: "Usuario",
        minWidth: 100,
        align: "right",
      },
    ],
    mensajes: null,
    cargandoMensajes: true,
    error: null,
    empresa: null,
  };

  const [state, dispatch] = useReducer(MensajeReducer, initialState);

  const enviarMensaje = async (datos) => {
    cargando();
    const { mensaje, cuenta } = datos;

    var meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    var f = new Date();
    const fecha =
      diasSemana[f.getDay()] +
      ", " +
      f.getDate() +
      " de " +
      meses[f.getMonth()] +
      " de " +
      f.getFullYear();

    const Mensaje = {
      mensaje: mensaje.contenido,
      numbers: mensaje.numeros,
      empresa: cuenta.empresa,
      codEmpresa: cuenta.codEmpresa,
      usuario: cuenta.userName,
      fecha: fecha,
      orderFecha: Date.now(),
    };

    try {
      const respuesta = await clienteAxios.post("/mensaje/sms", { Mensaje });
      const datos = {
        empresa: respuesta.data.editado,
        mensajes: respuesta.data.mensajes.data,
      };
      console.log(respuesta);
      await    dispatch({
        type: AGREGAR_MENSAJES,
        payload: datos,
      });

      limpiarErrores();
    } catch (error) {
      await dispatch({
        type: ENVIAR_MENSAJE_ERROR,
        payload: error.response.data.msg,
      });
      limpiarErrores();
    }
  };

  const obtenerMensajes = async (codEmpresa) => {
    cargando();
    const mensajes = await clienteAxios.post("/mensaje/obtener", { codEmpresa });

    dispatch({
      type: OBTENER_MENSAJES,
      payload: mensajes.data.mensajes,
    });
  };

  const generarPdf = async () => {
    const datos = {
      columns: state.columns,
      mensajes: state.mensajes,
    };

    const pdf = await clienteAxios.post(
      "/pdf/generar",
      { datos },
      { responseType: "blob" }
    );
    const pdfBlob = new Blob([pdf.data], { type: "application/pdf" });
    saveAs(pdfBlob, "Mensajes.pdf");
  };

  const quitarMensajes = async () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };
  const limpiarErrores = async () => {
    setTimeout(() => {
      dispatch({
        type: LIMPIAR_MENSAJE,
      });
    }, 3000);
  };
  const cargando = () => {
    dispatch({
      type: CARGANDO,
    });
  };

  return (
    <MensajeContext.Provider
      value={{
        //state
        columns: state.columns,
        mensajes: state.mensajes,
        cargandoMensajes: state.cargandoMensajes,
        error: state.error,
        empresa: state.empresa,
        //funcion
        enviarMensaje,
        obtenerMensajes,
        quitarMensajes,
        generarPdf,
      }}
    >
      {props.children}
    </MensajeContext.Provider>
  );
};

export default MensajeState;
