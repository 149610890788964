import React, { useEffect, useContext } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextsmsIcon from "@material-ui/icons/Textsms";
import { SmsFailed } from "@material-ui/icons";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import FormSms from "../../components/FormSms/FormSms.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import AuthContext from "../../Context/auth/AuthContext";
import MensajeContext from "../../Context/Mensajes/MensajeContext";
import EmpresaContext from "../../Context/Empresa/EmpresaContext.js";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const { cuenta, cargando } = useContext(AuthContext);
  const { mensajes, obtenerMensajes, cargandoMensajes, empresa  } = useContext(
    MensajeContext
  );
  const { obtenerEmpresas, filas, cargandoEmpresas , editarEmpresa } = useContext(
    EmpresaContext
  );
  const classes = useStyles();
  useEffect(() => {
    if (!mensajes) {
      obtenerMensajes(cuenta.codEmpresa);
    }
    if (!filas) {
      obtenerEmpresas();
    }
    //eslint-disable-next-line
  }, [cuenta, mensajes, filas]);
  useEffect(() => {
    if (empresa) {
      editarEmpresa({empresa})
    }
    //eslint-disable-next-line
  }, [empresa]);
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const diasSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const f = new Date();
  const fecha =
    diasSemana[f.getDay()] +
    ", " +
    f.getDate() +
    " de " +
    meses[f.getMonth()] +
    " de " +
    f.getFullYear();
  let contador = 0;
  let MensajesHoy = () => {
    mensajes.forEach((mensaje) => {
      if (mensaje.fecha === fecha) {
        contador++;
      }
    });
    return contador;
  };

  const empresaPaquete = () => {
    return filas.find((fila) => fila.nombre === cuenta.empresa);
  };

  if (!cuenta) return null;
  if (!mensajes) return null;
  if (!filas) return null;

  return (
    <div>
      {cargandoEmpresas || cargandoMensajes || cargando ? (
        <Box display="flex" justifyContent="center" width="100%" m={4}>
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </Box>
      ) : null}{" "}
      <GridContainer>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <TextsmsIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Enviados Hoy:</p>
              <h3 className={classes.cardTitle}>{MensajesHoy()}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                Total de sms enviados hoy.
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <BarChartIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Enviados:</p>
              <h3 className={classes.cardTitle}>{mensajes.length}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Total de sms enviados.</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <AddShoppingCartIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Paquete Contratado:</p>
              <h3 className={classes.cardTitle}>{empresaPaquete().paquete}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Paquete de sms contratado.</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <SmsFailed />
              </CardIcon>
              <p className={classes.cardCategory}>SMS Restantes:</p>
              <h3 className={classes.cardTitle}>
                {empresaPaquete().paquete - mensajes.length}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Total de sms restantes.</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Enviar SMS</h4>
            </CardHeader>
            <CardBody>
              {/* FORMULARIO DE ENVIAR SMS */}
              <FormSms paquete={empresaPaquete().paquete} enviados={mensajes.length} restantes={  empresaPaquete().paquete - mensajes.length } />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>SMS ENVIADOS HOY</h4>
            </CardHeader>
            <CardBody>{/* TABLE */}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
