import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import authContext from "../../Context/auth/AuthContext";

const RutaPrivada = ({ component: Component, ...props }) => {
  const { autenticado, usuarioAutenticado , cargando  } = useContext(authContext);

  useEffect(() => {
    usuarioAutenticado();
    //eslint-disable-next-line 
  },[]);
  return (
    <Route
      {...props}
      render={(props) =>
        !autenticado  && !cargando  ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

export default RutaPrivada;
