import React, { useContext, useEffect, createRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

//tabla
import TablaUsuarios from "../../components/TablaUsuarios/TablaUsuarios";

//CONTEXT

import AlertaContext from "../../Context/alertas/AlertaContext";
import UsuarioContext from "../../Context/Usuario/UsuarioContext.js";
import AuthContext from "../../Context/auth/AuthContext";
import { Alert } from "@material-ui/lab";
import { PictureAsPdf, ChromeReaderMode } from "@material-ui/icons";
import { Box, IconButton } from "@material-ui/core";
import TablaExcel from "react-html-table-to-excel";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    flexGrow: 1,
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  ocultar: {
    display: "none",
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const inputEl = createRef();
  //extrayendo valores del context
  const focusInput = () => {
    inputEl.current.handleDownload();
  };
  //extrayendo valores del context

  const { mensaje, cargandoUsuarios, generarPdf } = useContext(UsuarioContext);
  const { mostrarAlerta, alerta } = useContext(AlertaContext);
  const { cuenta } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    if (mensaje) {
      
      mostrarAlerta(mensaje);
    }
    //eslint-disable-next-line
  }, [mensaje]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {cargandoUsuarios ? (
          <Box display="flex" justifyContent="center" width="100%" m={4}>
            <div className="sk-chase">
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
              <div className="sk-chase-dot"></div>
            </div>
          </Box>
        ) : null}
        <Card>
          <CardHeader color="primary">
            <Box display="flex">
              <h4 className={classes.cardTitleWhite}>Usuarios Registrados</h4>
              <IconButton
                aria-label=""
                color="inherit"
                onClick={() => generarPdf(cuenta)}
              >
                <PictureAsPdf />
              </IconButton>
              <IconButton
                aria-label=""
                color="inherit"
                className={classes.color}
                onClick={focusInput}
              >
                <ChromeReaderMode />
              </IconButton>

              <TablaExcel
                ref={inputEl}
                className={classes.ocultar}
                id="botonExportar"
                table="tablaUsuarios"
                filename="Usuarios"
                sheet="pagina 1"
              />
            </Box>
          </CardHeader>
          <CardBody>
            {alerta ? <Alert severity="warning">{alerta} </Alert> : null}
            <TablaUsuarios />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
