import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button";
import MensajeContext from "../../Context/Mensajes/MensajeContext.js";
import AuthContext from "../../Context/auth/AuthContext";
import AlertaContext from "../../Context/alertas/AlertaContext.js";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#00acc1",
    "&:hover": {
      background: "#00acc1",
    },
  },
  input: {
    margin: "8px 0px",
  },
}));

export default function MultilineTextFields(props) {
  const { paquete, enviados , restantes } = props;
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  const [cantidadSms, setCantidadSms] = useState(0)
  const [cantidadCaracteresSMS, setCantidadCaracteresSMS] = useState(0)
  const [mensaje, setmensaje] = useState({
    contenido: "",
    numeros: "",
  });
  const { contenido, numeros } = mensaje;

  const { enviarMensaje, error } = useContext(MensajeContext);
  const { cuenta } = useContext(AuthContext);

  const onHandleCHangeMensaje = (e) => {
    setmensaje({
      ...mensaje,
      [e.target.name]: e.target.value,
    });
  };

  //SUMA CANTIDAD DE SMS A ENVIAR
  const aumentarCantidadSms = (e) =>{
    let canti = e.target.value.length+1

    if(e.keyCode === 8){
      canti =  canti - 2;
    }
    setCantidadCaracteresSMS(canti)
    if(canti === 0){
      setCantidadSms(0)
    }
    if((canti >= 1) && (canti <= 160)){
      setCantidadSms(1)
    }
    if((canti >= 161) && (canti <= 280)){
      setCantidadSms(2)
    }
    if((canti >= 281) && (canti <= 440)){
      setCantidadSms(3)
    }
    if((canti >= 441)){
      return mostrarAlerta(`Demasiados sms por enviar`);
    }
  }

  const handleSubmit = () => {
    if (contenido.trim() === "" || numeros.trim() === "") {
      return mostrarAlerta("Todos los campos son necesarios");
    }

    if (enviados >= paquete) {
      return mostrarAlerta("Paquete consumido");
    }
    const cantidadMensajes = numeros.split(",");
    if(cantidadMensajes.length + enviados > Number(paquete)){
      return mostrarAlerta(`Solo tienes  ${restantes} SMS disponibles .`);
    }

    enviarMensaje({ cuenta, mensaje });

    setmensaje({
      contenido: "",
      numeros: "",
    });
  };

  useEffect(() => {
    if (error) {
      mostrarAlerta(error);
    }
    //eslint-disable-next-line
  }, [error]);
  const classes = useStyles();

  return (
    <form noValidate autoComplete="off">
      {alerta ? <Alert severity="error">{alerta} </Alert> : null}
      <div>
        <TextField
          label="Numero/s"
          multiline
          rows={2}
          placeholder="Ingrese números separados por comas: 0987654321,0987654321"
          variant="outlined"
          fullWidth={true}
          className={classes.input}
          name="numeros"
          value={numeros}
          onChange={onHandleCHangeMensaje}
        />
        <TextField
          label="Mensaje"
          multiline
          rows={3}
          placeholder="Escriba el mensaje"
          variant="outlined"
          name="contenido"
          value={contenido}
          fullWidth={true}
          className={classes.input}
          onChange={onHandleCHangeMensaje}
          onKeyDown={aumentarCantidadSms}
        />
        <Typography color="textSecondary" align="right">
          <span>{cantidadSms} sms</span>{" "}
          <span>{cantidadCaracteresSMS}</span>
        </Typography>
      </div>
      <div>
        <Button
          variant="contained"
          fullWidth={true}
          className={classes.button}
          onClick={() => handleSubmit()}
        >
          Enviar
        </Button>
      </div>
    </form>
  );
}
