import {
  ELIMINAR_USUARIO,
  ELIMINAR_USUARIO_ERROR,
  CAMBIAR_USUARIO,
  EDITAR_USUARIO,
  EDITAR_USUARIO_ERROR,
  AGREGAR_USUARIO,
  AGREGAR_USUARIO_ERROR,
  OBTENER_USUARIOS,
  OBTENER_USUARIOS_ERROR,
  LIMPIAR_MENSAJE,
  CARGANDO,
} from "../types/index";

export default (state, action) => {
  switch (action.type) {
    case CAMBIAR_USUARIO:
      return {
        ...state,
        usuarioActivo: action.payload,
      };
    case OBTENER_USUARIOS:
      return {
        ...state,
        usuarios: action.payload.sort((a, b) => b.fecha - a.fecha),
        cargandoUsuarios: false,
      };
    case AGREGAR_USUARIO:
      const nuevoArreglo = state.usuarios;

      const { codEmpresa } = action.payload;

      const empresaInfo = codEmpresa.split("-");
      
      const usuario = {
        ...action.payload,
        codEmpresa: empresaInfo[0],
        empresa: empresaInfo[1],
        fecha: Date.now(),
      };
      nuevoArreglo.push({
        ...usuario,
        boton: "Create",
      });
      
      return {
        ...state,
        usuarios: nuevoArreglo.sort((a, b) => b.fecha - a.fecha),
        mensaje: `Usuario ${state.usuarioActivo.nombres} agregado.`,
        usuarioActivo: {
          codEmpresa: "",
          nombres: "",
          userName: "",
          email: "",
          password: "",
          rol: "usuario",
          boton: "Create",
          estado: "ACTIVO",
        },
        cargandoUsuarios: false
      };
    case EDITAR_USUARIO:
      
      const user = action.payload.usuario;
      const empresa = user.codEmpresa.split("-");
      user.codEmpresa = empresa[0];
      user.empresa = empresa[1];
      user.fecha = Date.now();
      return {
        ...state,
        usuarios: state.usuarios
          .map((fila) => {
            
            if (fila._id !== action.payload._id) {
              return fila;
            } else {
              return user;
            }
          })
          .sort((a, b) => b.fecha - a.fecha),
        mensaje: `Usuario ${state.usuarioActivo.nombres} editado.`,
        usuarioActivo: {
          codEmpresa: "",
          nombres: "",
          userName: "",
          email: "",
          password: "",
          rol: "usuario",
          boton: "Create",
          estado: "ACTIVO",
        },
        cargandoUsuarios: false
      };
    case ELIMINAR_USUARIO:
      
      return {
        ...state,
        usuarios: state.usuarios
          .filter((fila) => fila._id !== action.payload._id)
          .sort((a, b) => b.fecha - a.fecha),
        mensaje: action.payload.msg,
        cargandoUsuarios: false
      };
    case ELIMINAR_USUARIO_ERROR:
    case EDITAR_USUARIO_ERROR:
    case AGREGAR_USUARIO_ERROR:
    case OBTENER_USUARIOS_ERROR:
      return {
        ...state,
        mensaje: action.payload,
        cargandoUsuarios: false
      };
    case LIMPIAR_MENSAJE:
      return {
        ...state,
        mensaje: null,
      };
      case CARGANDO: 
      return {
        ...state, 
        cargandoUsuarios: true
      }
    default:
      return {
        ...state,
      };
  }
};
