// @material-ui/icons
import { Dashboard ,Mail , HomeWork , People} from "@material-ui/icons/";

import DashboardPage from "./views/Dashboard/Dashboard.js";
import Reporte from "./views/Reporte/Reporte.js";
import Mensajes from "./views/Mensajes/Mensajes";
import Usuarios from "./views/usuarios/Usuarios";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Tablero",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Mensajes",
    icon: Mail,
    component: Mensajes,
    layout: "/admin"
  },
  {
    path: "/empresas",
    name: "Empresas",
    icon: HomeWork,
    component: Reporte,
    layout: "/admin"
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: People,
    component: Usuarios,
    layout: "/admin"
  },

];

export default dashboardRoutes;
