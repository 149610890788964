import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom'
import imagen from "../../assets/img/neitorLogo.jpeg"

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AuthContext from "../../Context/auth/AuthContext";
import AlertaContext from "../../Context/alertas/AlertaContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"© "}{new Date().getFullYear()}{" "}
      <Link color="" style={{"color":"#616161"}} to="https://neitor.com/">
         Neitor Mensajería.
      </Link>
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo:{
    width: "90px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  color: {
    color: "black"
  }
}));
export default function Login(props) {
  //valores del context
  const { iniciarSesion, autenticado, mensaje } = useContext(AuthContext);
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  //Stata initial
  const [datosLogin, setDatosLogin] = useState({
    codigo: "",
    email: "",
    password: "",
  });

  //Destructuracion
  const { codigo, email, password } = datosLogin;

  //Obtener valores
  const ObtenerValores = (e) => {
    setDatosLogin({
      ...datosLogin,
      [e.target.name]: e.target.value,
    });
  };

  // Funcion Login
  const Login = (e) => {
    e.preventDefault();
    // Validar Campos Vacios
    if (codigo.trim() === "" || email.trim() === "" || password.trim() === "") {
      return mostrarAlerta("Todos los campos son necesarios.");
    }
    iniciarSesion(datosLogin);
  };
  const classes = useStyles();
  useEffect(() => {
    if (autenticado) {
      props.history.push("/admin/dashboard");
      return;
    }
    if (mensaje) {
      mostrarAlerta(mensaje);
    }
    //eslint-disable-next-line
  }, [autenticado, mensaje]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={imagen} className={classes.logo} alt=""/>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        {alerta ? <Alert severity="error">{alerta} </Alert> : null}
        <form className={classes.form} noValidate onSubmit={Login}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="codigo"
            label="Código Empresa"
            name="codigo"
            autoComplete="Código empresa"
            autoFocus
            value={codigo}
            onChange={ObtenerValores}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario / Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={ObtenerValores}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={ObtenerValores}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar
          </Button>
          <Grid container>
            <Grid item>
              <Link to="/Recuperar" className={classes.color} >
                Olvidastes tu contraseña?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
