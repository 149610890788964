import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar

import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from "../routes.js";

import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";

import bgImage from "../assets/img/ladoDerecho.png";
import AuthContext from "../Context/auth/AuthContext";

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const { usuarioAutenticado, cuenta } = useContext(AuthContext);

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const color = "green";
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // initialize and destroy the PerfectScrollbar plugin


  React.useEffect(() => {
    usuarioAutenticado();
    //eslint-disable-next-line
  }, []);
  if (!cuenta) return null;
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"SMS Panel"}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {routes.map((prop, key) => {
                if (prop.layout === "/admin") {
                  return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                }
                return null;
              })}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
