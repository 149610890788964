import React, { useState, useEffect, useContext } from "react";
import {
  Modal as Modale,
  Typography,
  Divider,
  TextField,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import UsuarioContext from "../../Context/Usuario/UsuarioContext";
import EmpresaContext from "../../Context/Empresa/EmpresaContext";
import AlertaContext from "../../Context/alertas/AlertaContext";
import AuthContext from "../../Context/auth/AuthContext";
import { Alert } from "@material-ui/lab";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    height: "50%",
  },
  container: {
    maxHeight: "440",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  align: {
    textAlign: "left",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  marginFullWidthInput: {
    margin: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  box: {
    flexGrow: 1,
  },
  boton: {
    background: " linear-gradient(to bottom right, #5abe5e, #3b9b3e)",
    color: "white",
    transition: "all 1s",
    "&:hover": {
      background: " linear-gradient(to bottom right, #3b9b3e, #5abe5e)",
    },
  },
}));

const Modal = (props) => {
  //extrayendo valores del context
  const { usuarioActivo, editarUsuario, agregarUsuario } = useContext(
    UsuarioContext
  );

  const { filas, obtenerEmpresas } = useContext(EmpresaContext);
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  //hook de estilos
  const clases = useStyles();
  //extrayendo valores de los props y colocandolos en el state
  const [usuario, setusuario] = useState({ ...usuarioActivo });
  const { cuenta } = useContext(AuthContext);
  //define si se esta editando o no
  const [editar, seteditar] = useState(false);
  //cuando la emoresa activa  cambia coloco el state de llos props en el state de usuario
  useEffect(() => {
    setusuario({
      ...usuarioActivo,
      codEmpresa: usuarioActivo.codEmpresa + "-" + usuarioActivo.empresa,
    });
    //si la usuario activa tiene un id es por que esta en la base de datos por lo tanto
    //el documento existe  ,por lo tanto esta editando.

    if (usuarioActivo._id) {
      seteditar(true);
    }
    if (!filas) {
      obtenerEmpresas();
    }
    //eslint-disable-next-line
  }, [usuarioActivo, filas]);
  //   extrayendo valores del state
  const {
    codEmpresa,
    nombres,
    userName,
    email,
    password,
    rol,
    _id,
    estado,
  } = usuario;

  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  //funcion que escucha los cambios de los inputs
  const cambiarState = (prop) => (e) => {
    setusuario({ ...usuario, [prop]: e.target.value });
  };

  //cuando se hace submit al editar
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      codEmpresa.trim() === "" ||
      nombres.trim() === "" ||
      userName.trim() === "" ||
      email.trim() === "" ||
      password === "" ||
      rol.trim() === ""
    ) {
      return mostrarAlerta("Todos los campos son necesarios");
    }
    
    editarUsuario({ _id, usuario });
    props.handleClose();
    seteditar(false);
  };

  //cuando se hace submit para crear una nueva usuario
  const handleSubmitAgregar = (e) => {
    e.preventDefault();
    if (
      codEmpresa.trim() === "" ||
      nombres.trim() === "" ||
      userName.trim() === "" ||
      email.trim() === "" ||
      password === "" ||
      rol.trim() === ""
    ) {
      return mostrarAlerta("Todos los campos son necesarios");
    }
    agregarUsuario(usuario);
    seteditar(false);
    props.handleClose();
  };
  if (!filas) return null;
  if (!cuenta) return null;
  return (
    <Modale open={props.open}>
      <div style={modalStyle} className={clases.paper}>
        {alerta ? <Alert severity="error">{alerta} </Alert> : null}
        <form>
          {!editar ? (
            <Typography variant="h5" color="initial" className={clases.align}>
              Nuevo usuario.
            </Typography>
          ) : (
            <Typography variant="h5" color="initial" className={clases.align}>
              Editar usuario.
            </Typography>
          )}
          <Divider />
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <TextField
              value={nombres}
              className={clases.margin}
              label="Nombres"
              onChange={cambiarState("nombres")}
              fullWidth
            />
            {cuenta.rol === "usuario" ? null : (
              <TextField
                value={userName}
                className={clases.margin}
                name="userName"
                label="Usuario"
                onChange={cambiarState("userName")}
                fullWidth
              />
            )}

            <TextField
              type="email"
              name="email"
              label="Email"
              value={email}
              className={clases.margin}
              onChange={cambiarState("email")}
              fullWidth
            />

            <TextField
              type="password"
              label="password"
              name="Password"
              className={clases.margin}
              onChange={cambiarState("password")}
              fullWidth
            />
            {cuenta.rol === "usuario" ? null : (
              <FormControl className={clases.margin} fullWidth>
                <InputLabel id="demo-simple-select-label ">Rol</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rol}
                  onChange={cambiarState("rol")}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="usuario">Usuario</MenuItem>
                </Select>
              </FormControl>
            )}

            {cuenta.rol === "usuario" ? null : (
              <FormControl className={clases.margin} fullWidth>
                <InputLabel id="demo-simple-select-label ">Empresa</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={codEmpresa}
                  onChange={cambiarState("codEmpresa")}
                >
                  {filas.map((fila) => (
                    <MenuItem
                      key={fila._id}
                      value={fila.codigo + "-" + fila.nombre}
                    >
                      {fila.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

          {cuenta.rol === "usuario" ? null : (
            <FormControl component="fieldset">
              <FormLabel component="legend">Estado</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="estado"
                onChange={cambiarState("estado")}
                value={estado}
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    value="ACTIVO"
                    control={<Radio />}
                    label="Activo"
                  />
                  <FormControlLabel
                    value="INACTIVO"
                    control={<Radio />}
                    label="Inactivo"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          )}
          </Box>
          <Divider />
          <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
            {!editar ? (
              <Button
                variant="contained"
                color="primary"
                className={clases.boton}
                type="submit"
                onClick={handleSubmitAgregar}
              >
                Crear
              </Button>
            ) : (
              <Button
                variant="contained"
                className={clases.boton}
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            )}
            <Box m={1}></Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.handleClose();
                seteditar(false);
              }}
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </div>
    </Modale>
  );
};

export default Modal;
