import React, { useState, useEffect, useContext } from "react";
import {
  Modal as Modale,
  Typography,
  Divider,
  TextField,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Button,
} from "@material-ui/core";

import EmpresaContext from "../../Context/Empresa/EmpresaContext";
import AlertaContext from "../../Context/alertas/AlertaContext";
import { Alert } from "@material-ui/lab";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    height: "50%",
  },
  container: {
    maxHeight: "440",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  align: {
    textAlign: "left",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    width: "47%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  marginFullWidthInput: {
    margin: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  box: {
    flexGrow: 1,
  },
  boton: {
    background: " linear-gradient(to bottom right, #5abe5e, #3b9b3e)",
    color: "white",
    transition: "all 1s",
    "&:hover": {
      background: " linear-gradient(to bottom right, #3b9b3e, #5abe5e)",
    },
  },
}));

const Modal = (props) => {
  //extrayendo valores del context
  const { empresaActiva, editarEmpresa, agregarEmpresa } = useContext(
    EmpresaContext
  );
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  //hook de estilos
  const clases = useStyles();
  //extrayendo valores de los props y colocandolos en el state
  const [empresa, setempresa] = useState({ ...empresaActiva });

  //define si se esta editando o no
  const [editar, seteditar] = useState(false);
  //cuando la emoresa activa  cambia coloco el state de llos props en el state de empresa
  useEffect(() => {
    setempresa({
      ...empresaActiva,
    });
    //si la empresa activa tiene un id es por que esta en la base de datos por lo tanto
    //el documento existe  ,por lo tanto esta editando.

    if (empresaActiva._id) {
      seteditar(true);
    }
  }, [empresaActiva]);
  //   extrayendo valores del state
  const {
    nombre,
    codigo,
    Ruc,
    Email,
    Celular,
    Direccion,
    paquete,
    smsEnviados,
    estado,
  } = empresa;
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  //funcion que escucha los cambios de los inputs
  const cambiarState = (prop) => (e) => {
    setempresa({ ...empresa, [prop]: e.target.value });
  };

  //cuando se hace submit al editar
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      nombre.trim() === "" ||
      estado.trim() === "" ||
      Ruc.trim() === "" ||
      Direccion.trim() === "" ||
      Celular === "" ||
      Email.trim() === "" ||
      codigo.trim() === "" ||
      paquete.trim() === ""
    ) {
      return mostrarAlerta("Todos los campos son obligatorios");
    }
    editarEmpresa({ Ruc, empresa });
    props.handleClose();
    seteditar(false);
  };

  //cuando se hace submit para crear una nueva empresa
  const handleSubmitAgregar = (e) => {
    e.preventDefault();
    if (
      nombre.trim() === "" ||
      estado.trim() === "" ||
      Ruc.trim() === "" ||
      Direccion.trim() === "" ||
      Celular === "" ||
      Email.trim() === "" ||
      codigo.trim() === "" ||
      paquete.trim() === ""
    ) {
      return mostrarAlerta("Todos los campos son obligatorios");
    }
    agregarEmpresa(empresa);
    props.handleClose();
    seteditar(false);
  };

  return (
    <Modale open={props.open}>
      <div style={modalStyle} className={clases.paper}>
        {alerta ? <Alert severity="error">{alerta} </Alert> : null}
        <form>
          {!editar ? (
            <Typography variant="h5" color="initial" className={clases.align}>
              Nueva Empresa.
            </Typography>
          ) : (
            <Typography variant="h5" color="initial" className={clases.align}>
              Editar Empresa.
            </Typography>
          )}
          <Divider />
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <TextField
              value={nombre}
              className={clases.margin}
              name="nombre"
              label="Nombre de Empresa"
              onChange={cambiarState("nombre")}
              fullWidth
            />
            <TextField
              value={codigo}
              className={clases.margin}
              name="codigo"
              label="Codigo"
              onChange={cambiarState("codigo")}
              fullWidth
            />{" "}
            <TextField
              value={Ruc}
              className={clases.margin}
              type="number"
              name="Ruc"
              label="Ruc"
              onChange={cambiarState("Ruc")}
              fullWidth
            />
            <TextField
              name="Email"
              label="Email"
              type="email"
              value={Email}
              className={clases.margin}
              onChange={cambiarState("Email")}
              fullWidth
            />
            <TextField
              value={Celular}
              label="Celular"
              name="Celular"
              className={clases.margin}
              onChange={cambiarState("Celular")}
              fullWidth
            />
            <TextField
              value={Direccion}
              name="Direccion"
              label="Direccion"
              className={clases.margin}
              onChange={cambiarState("Direccion")}
              fullWidth
            />
            <TextField
              name="paquete"
              type="number"
              label="Paquete contradado"
              className={clases.margin}
              value={paquete}
              onChange={cambiarState("paquete")}
              fullWidth
            />
            <TextField
              value={smsEnviados}
              type="number"
              name="smsEnviados"
              label="SMS Enviados"
              className={clases.margin}
              onChange={cambiarState("smsEnviados")}
              fullWidth
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">Estado</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="estado"
                onChange={cambiarState("estado")}
                value={estado}
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <FormControlLabel
                    value="ACTIVO"
                    control={<Radio />}
                    label="ACTIVO"
                  />
                  <FormControlLabel
                    value="INACTIVO"
                    control={<Radio />}
                    label="INACTIVO"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          <Divider />
          <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
            {!editar ? (
              <Button
                variant="contained"
                color="inherit"
                type="submit"
                className={clases.boton}
                onClick={handleSubmitAgregar}
              >
                Crear
              </Button>
            ) : (
              <Button
                variant="contained"
                className={clases.boton}
                color="inherit"
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            )}
            <Box m={1}></Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                props.handleClose();
                seteditar(false);
              }}
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </div>
    </Modale>
  );
};

export default Modal;
