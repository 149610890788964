export const ELIMINAR_EMPRESA = "ELIMINAR_EMPRESA";
export const ELIMINAR_EMPRESA_ERROR = "ELIMINAR_EMPRESA_ERROR";
export const CAMBIAR_EMPRESA = "CAMBIAR_EMPRESA";
export const EDITAR_EMPRESA = "EDITAR_EMPRESA";
export const EDITAR_EMPRESA_ERROR = "EDITAR_EMPRESA_ERROR";
export const AGREGAR_EMPRESA = "AGREGAR_EMPRESA";
export const AGREGAR_EMPRESA_ERROR = "AGREGAR_EMPRESA_ERROR";
export const OBTENER_EMPRESAS = "OBTENER_EMPRESAS";

//cuenta
export const INICIO_EXITOSO = "INICIO_EXITOSO";
export const INICIO_ERROR = "INICIO_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";

//alertas
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";
export const RECUPERACION_EXITO = "RECUPERACION_EXITO";

//mensajes
export const OBTENER_MENSAJES = "OBTENER_MENSAJES";
export const AGREGAR_MENSAJES = "AGREGAR_MENSAJES";
export const ENVIAR_MENSAJE_ERROR = "ENVIAR_MENSAJE_ERROR"
export const QUITAR_EMPRESA = "QUITAR_EMPRESA"
//usuarios
export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO";
export const ELIMINAR_USUARIO_ERROR = "ELIMINAR_USUARIO_ERROR";
export const CAMBIAR_USUARIO = "CAMBIAR_USUARIO";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const EDITAR_USUARIO_ERROR = "EDITAR_USUARIO_ERROR";
export const AGREGAR_USUARIO = "AGREGAR_USUARIO";
export const AGREGAR_USUARIO_ERROR = "AGREGAR_USUARIO_ERROR";
export const OBTENER_USUARIOS = "OBTENER_USUARIOS";
export const OBTENER_USUARIOS_ERROR = "OBTENER_USUARIOS_ERROR";
//error
export const LIMPIAR_MENSAJE = "LIMPIAR_MENSAJE";
//cargando 
export const CARGANDO= "CARGANDO"
//
