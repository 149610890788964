import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AuthContext from "../../Context/auth/AuthContext";
import AlertaContext from "../../Context/alertas/AlertaContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://neitor.com/">
        Mesajería Neitor
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boton: {
    margin: theme.spacing(1),
    background: " linear-gradient(to bottom right, #5abe5e, #3b9b3e)",
    color: "white",
    transition: "all 1s",
    "&:hover": {
      background: " linear-gradient(to bottom right, #3b9b3e, #5abe5e)",
    },
  },
  margen: {
    margin: theme.spacing(1),
  },
  color :{
      color:"black"
  }
}));
export default function Login(props) {
  //valores del context
  const { RecuperarContraseña, autenticado, mensaje , exito} = useContext(AuthContext);
  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  //Stata initial
  const [datosLogin, setDatosLogin] = useState({
    email: "",
  });

  //Destructuracion
  const { email } = datosLogin;

  //Obtener valores
  const ObtenerValores = (e) => {
    setDatosLogin({
      ...datosLogin,
      [e.target.name]: e.target.value,
    });
  };

  // Funcion Login
  const Login = (e) => {
    e.preventDefault();
    // Validar Campos Vacios
    if (email.trim() === "") {
      return mostrarAlerta("Todos los campos son necesarios.");
    }
    
    RecuperarContraseña(datosLogin);
  };
  const Cancelar = () => {
      props.history.push('/login')
  }
  const classes = useStyles();
  useEffect(() => {

    if (mensaje) {
      mostrarAlerta(mensaje);
    }
    //eslint-disable-next-line
  }, [autenticado, mensaje]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" color="initial">
          Recuperar Contraseña.
        </Typography>
     
        
        {alerta ? <Alert severity="error">{alerta} </Alert> : null}
        {exito ? <Alert severity="success">{exito} </Alert> : null}
        <form className={classes.form} noValidate onSubmit={Login}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario o email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={ObtenerValores}
          />

          <Box mt={1} width="100%" display="flex">
            <Button
              variant="contained"
              fullWidth
              className={classes.boton}
              type="submit"
            >
              Recuperar
            </Button>
                    
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.margen}
              onClick={Cancelar}
            >
              Cancelar
            </Button>
          </Box>
          <Grid container>
            <Grid item>
      
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
