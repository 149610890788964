import {
  OBTENER_MENSAJES,
  CERRAR_SESION,
  AGREGAR_MENSAJES,
  ENVIAR_MENSAJE_ERROR,
  CARGANDO,
  LIMPIAR_MENSAJE,
} from "../types/index";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_MENSAJES:
      return {
        ...state,
        mensajes: action.payload.sort((a, b) => b.orderFecha - a.orderFecha),
        cargandoMensajes: false,
      };
    case AGREGAR_MENSAJES:
      const nuevoArreglo = state.mensajes;
      console.log(action.payload);
      action.payload.mensajes.forEach((mensaje) => {
        nuevoArreglo.push(mensaje);
      });
      nuevoArreglo.sort((a, b) => b.orderFecha - a.orderFecha);
      return {
        ...state,
        mensajes: nuevoArreglo,
        cargandoMensajes: false,
        empresa: action.payload.empresa,
      };
    case CERRAR_SESION:
      return {
        ...state,
        mensajes: null,
      };
    case ENVIAR_MENSAJE_ERROR:
      return {
        ...state,
        error: action.payload,
        empresa: null,
        cargandoMensajes: false,
      };
    case CARGANDO:
      return {
        ...state,
        cargandoMensajes: true,
      };
    case LIMPIAR_MENSAJE:
      return {
        ...state,
        error: null,
        empresa: null,
        cargandoMensajes: false,
      };
    default:
      return {
        ...state,
      };
  }
};
