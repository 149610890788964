//types
import {
  ELIMINAR_EMPRESA,
  CAMBIAR_EMPRESA,
  EDITAR_EMPRESA,
  AGREGAR_EMPRESA,
  OBTENER_EMPRESAS,
  ELIMINAR_EMPRESA_ERROR,
  EDITAR_EMPRESA_ERROR,
  AGREGAR_EMPRESA_ERROR,
  LIMPIAR_MENSAJE,
  CARGANDO,
} from "../types/index";

export default (state, action) => {
  switch (action.type) {
    case ELIMINAR_EMPRESA:
      
      return {
        ...state,
        filas: state.filas
          .filter((fila) => fila._id !== action.payload._id)
          .sort((a, b) => b.fecha - a.fecha),
        mensaje: action.payload.msg,
        cargandoEmpresas: false,
      };
    case EDITAR_EMPRESA_ERROR:
    case ELIMINAR_EMPRESA_ERROR:
    case AGREGAR_EMPRESA_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    case CAMBIAR_EMPRESA:
      return {
        ...state,
        empresaActiva: action.payload,
      };
    case EDITAR_EMPRESA:
      const nuevaEmpresa = {
        ...action.payload.empresa,
        fecha: Date.now(),
      };
      return {
        ...state,
        filas: state.filas
          .map((fila) => {
            if (fila._id !== action.payload.empresa._id) {
              return fila;
            } else {
              return nuevaEmpresa;
            }
          })
          .sort((a, b) => b.fecha - a.fecha),
        mensaje: `Empresa ${state.empresaActiva.nombre} editada.`,
        empresaActiva: {
          nombre: "",
          codigo: "",
          Ruc: "",
          estado: "ACTIVO",
          Direccion: "",
          Celular: "",
          Email: "",
          smsEnviados: 0,
          rol: "empresa",
          paquete: "",
          boton: "Create",
        },
        cargandoEmpresas: false,
      };
    case AGREGAR_EMPRESA:
      const nuevoArreglo = state.filas;
      nuevoArreglo.push({
        ...action.payload,
        boton: "Create",
        fecha: Date.now(),
      });
      const arreglo = nuevoArreglo.sort((a, b) => b.fecha - a.fecha);
      return {
        ...state,
        filas: arreglo,
        mensaje: `Empresa ${state.empresaActiva.nombre} agregada.`,
        empresaActiva: {
          nombre: "",
          codigo: "",
          Ruc: "",
          estado: "ACTIVO",
          Direccion: "",
          Celular: "",
          Email: "",
          smsEnviados: 0,
          rol: "empresa",
          paquete: "",

          boton: "Create",
        },

        cargandoEmpresas: false,
      };
    case OBTENER_EMPRESAS:
      return {
        ...state,
        filas: action.payload.sort((a, b) => b.fecha - a.fecha),
        cargandoEmpresas: false,
      };
    case LIMPIAR_MENSAJE:
      return {
        ...state,
        mensaje: null,
      };
    case CARGANDO:
      return {
        ...state,
        cargandoEmpresas: true,
      };
    default:
      return {
        ...state,
      };
  }
};
