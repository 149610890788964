import React, { useReducer } from "react";

//context
import EmpresaContext from "./EmpresaContext";

//reducer
import EmpresaReducer from "./EmpresaReducer";
//cliente axios
import clienteAxios from "../../config/axios";
import { saveAs } from "file-saver";
//TPES
import {
  ELIMINAR_EMPRESA,
  CAMBIAR_EMPRESA,
  EDITAR_EMPRESA,
  AGREGAR_EMPRESA,
  OBTENER_EMPRESAS,
  ELIMINAR_EMPRESA_ERROR,
  EDITAR_EMPRESA_ERROR,
  AGREGAR_EMPRESA_ERROR,
  LIMPIAR_MENSAJE,
  CARGANDO,
} from "../types";

const EmpresaState = (props) => {
  const initialState = {
    empresaActiva: {
      nombre: "",
      codigo: "",
      Ruc: "",
      estado: "ACTIVO",
      Direccion: "",
      Celular: "",
      Email: "",
      smsEnviados: 0,
      rol: "empresa",
      paquete: "",
    },
    columnas: [
      {
        id: "Ruc",
        label: "Ruc",
        minWidth: 10,

        key: "llave3",
      },
      {
        id: "nombre",
        label: "Empresa",
        minWidth: 165,
        key: "llave12",
        align: "center",
      },
      {
        id: "codigo",
        label: "Codigo",
        minWidth: 10,
        key: "llave13",
        align: "center",
      },
      {
        id: "estado",
        label: "Estado",
        minWidth: 10,
        key: "llav10",
        align: "center",
      },
      {
        id: "paquete",
        label: "Plan SMS",
        minWidth: 100,
        key: "llave9",
        align: "center",
      },
      {
        id: "smsEnviados",
        label: "Consumido",
        minWidth: 10,
        key: "llave8",
        align: "center",
      },

      {
        id: "Email",
        label: "Email",
        minWidth: 10,
        align: "center",
        key: "llave6",
      },
      {
        id: "Celular",
        label: "Celular",
        minWidth: 10,
        align: "center",
        key: "llave11",
      },

      {
        id: "boton",
        label: "Editar/Eliminar",
        minWidth: 105,
        align: "right",
        key: "llave7",
      },
    ],
    filas: null,
    mensaje: null,
    cargandoEmpresas: true,
  };

  const [state, dispatch] = useReducer(EmpresaReducer, initialState);

  const obtenerEmpresas = async () => {
    cargando();
    const empresas = await clienteAxios.get("/empresa");

    dispatch({
      type: OBTENER_EMPRESAS,
      payload: empresas.data.empresas,
    });
  };

  const removerEmpresa = async (_id) => {
    cargando();
    try {
      const msg = await clienteAxios.post("/empresa/eliminar", { _id });

      const payload = {
        _id,
        msg: msg.data.msg,
      };
      dispatch({
        type: ELIMINAR_EMPRESA,
        payload,
      });
      quitarMensaje();
    } catch (error) {
      
      dispatch({
        type: ELIMINAR_EMPRESA_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const onChangeEmpresaActiva = async (empresa) => {
    dispatch({
      type: CAMBIAR_EMPRESA,
      payload: empresa,
    });
  };

  const editarEmpresa = async (datos) => {
    cargando();
    try {
      await clienteAxios.post("/empresa/editar", datos);
      dispatch({
        type: EDITAR_EMPRESA,
        payload: datos,
      });
      quitarMensaje();
    } catch (error) {
      dispatch({
        type: EDITAR_EMPRESA_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };

  const agregarEmpresa = async (datos) => {
    cargando();
    try {
      const id = await clienteAxios.post("/empresa", datos);

      datos._id = id.data.id;
      dispatch({
        type: AGREGAR_EMPRESA,
        payload: datos,
      });
      quitarMensaje();
    } catch (error) {
      
      dispatch({
        type: AGREGAR_EMPRESA_ERROR,
        payload: error.response.data.msg,
      });
      quitarMensaje();
    }
  };
  const generarPdf = async (cuenta) => {
    
    let datos;
    if (cuenta.rol === "admin") {
      datos = {
        columnas: state.columnas.filter(columna => columna.id  !== "boton"),
        filas: state.filas,
      };
    } else {
      datos = {
        columnas:  state.columnas.filter(columna => columna.id  !== "boton"),
        filas: state.filas.filter((fila) => fila.nombre === cuenta.empresa),
      };
    }

    
    const pdf = await clienteAxios.post(
      "/pdf/empresa",
      { datos },
      { responseType: "blob" }
    );
    const pdfBlob = new Blob([pdf.data], { type: "application/pdf" });
    saveAs(pdfBlob, "empresa.pdf");
  };
  
  const quitarMensaje = () => {
    setTimeout(() => {
      dispatch({
        type: LIMPIAR_MENSAJE,
      });
    }, 4000);
  };
  const cargando = () => {
    dispatch({
      type: CARGANDO,
    });
  };
  return (
    <EmpresaContext.Provider
      value={{
        //state
        empresa: state.empresa,
        columnas: state.columnas,
        filas: state.filas,
        empresaActiva: state.empresaActiva,
        mensaje: state.mensaje,
        cargandoEmpresas: state.cargandoEmpresas,
        //funciones
        removerEmpresa,
        onChangeEmpresaActiva,
        editarEmpresa,
        agregarEmpresa,
        obtenerEmpresas,
        generarPdf,
      }}
    >
      {props.children}
    </EmpresaContext.Provider>
  );
};

export default EmpresaState;
