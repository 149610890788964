import React, { useReducer } from "react";

//context
import AlertaContext from "./AlertaContext";
//reducer
import AlertaReducer from "./AlertaReducer";

import { MOSTRAR_ALERTA, OCULTAR_ALERTA } from "../types/index";

const AlertaState = (props) => {
  const initialState = {
    alerta: null,
  };

  const [state, dispatch] = useReducer(AlertaReducer, initialState);

  const mostrarAlerta = (mensaje) => {
    dispatch({
      type: MOSTRAR_ALERTA,
      payload: mensaje,
    });

    setTimeout(() => {
      dispatch({
        type: OCULTAR_ALERTA,
      });
    }, 3000);
  };
  return (
    <AlertaContext.Provider value={{
        //state
         alerta : state.alerta ,
         //funcion
         mostrarAlerta
    }}>{props.children}</AlertaContext.Provider>
  );
};

export default AlertaState;
