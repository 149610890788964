//types
import {
  INICIO_EXITOSO,
  OBTENER_USUARIO,
  LOGIN_ERROR,
  INICIO_ERROR,
  CERRAR_SESION,
  LIMPIAR_MENSAJE,
  RECUPERACION_EXITO
} from "../types/index";
export default (state, action) => {
  switch (action.type) {
    case INICIO_EXITOSO:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        autenticado: true,
        mensaje: null,
        cargando : false
      };
    case OBTENER_USUARIO:
      return {
        ...state,
        cuenta: action.payload,
        autenticado: true,
        mensaje: null,
        cargando : false
      };
    case LOGIN_ERROR:
    case INICIO_ERROR:
      case CERRAR_SESION : 
      localStorage.removeItem('token')
      return {
        mensaje: action.payload,
        token : null,
        cuenta: null,
        autenticado: false,
        cargando : false
      };
      case LIMPIAR_MENSAJE:

        return {
          ...state,
          mensaje: null,
          exito : null
        }
        case RECUPERACION_EXITO:
          return {
            ...state,
            exito : action.payload
          }
    default:
      return {
        ...state,
      };
  }
};
